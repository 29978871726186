import React, { useState, useEffect, useCallback } from 'react';
import ProductItem from '../ProductItem/ProductItem';
import './ProductList.css';

const ProductList = ({ products, selectedProds, onSelectedProdsChange }) => {
  const [filteredProds, setFilteredProds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState('Bevande');

  useEffect(() => {
    if (filter != null) {
      let tmpProds = products.filter(p => p.productType === filter);
      setFilteredProds(tmpProds);
      const tmpCategories = tmpProds
        .map(prod => prod.category)
        .filter((value, index, self) => self.indexOf(value) === index);
      setCategories(tmpCategories);
    }
  }, [filter, products]);

  const onChangeQuantity = useCallback((prodName, quantity) => {
    const updatedProds = selectedProds.map(prod =>
      prod.productName === prodName ? { ...prod, quantity } : prod
    );
    onSelectedProdsChange(updatedProds); // Comunica il cambiamento ad App
  }, [selectedProds, onSelectedProdsChange]);

  const renderProducts = () => {
    if (filteredProds.length === 0) {
      return <div>Nessun prodotto disponibile</div>;
    }

    return categories.map(category => (
      <div key={category}>
        <h3 className="subcategory-label">{category}</h3>
        <div className="product-list-container">
          {filteredProds
            .filter(p => p.category === category)
            .map(product => (
              <ProductItem
                key={product.id}
                name={product.productName}
                price={product.cost}
                imagePath={`images/${product.imagePath}`}
                quantity={selectedProds.find(p => p.productName === product.productName)?.quantity || 0}
                onChangeQuantity={onChangeQuantity}
              />
            ))}
        </div>
      </div>
    ));
  };

  return (
    <div className="product-list">
      <div className="filter-buttons">
        <button
          onClick={() => setFilter('Bevande')}
          className={filter === 'Bevande' ? 'active' : ''}
        >
          Bevande
        </button>
        <button
          onClick={() => setFilter('Alimenti')}
          className={filter === 'Alimenti' ? 'active' : ''}
        >
          Alimenti
        </button>
        <button
          onClick={() => setFilter('Pellet')}
          className={filter === 'Pellet' ? 'active' : ''}
        >
          Pellet
        </button>
      </div>
      {renderProducts()}
    </div>
  );
};

export default ProductList;
