import Swal from 'sweetalert2';

export function displayInformationWindow() {
    Swal.fire({
        icon: 'info',
        html: `Partita IVA: 02166630661<br>
        Numero REA: AQ - 210927<br>
        Ortucchio (AQ)<br>
        Via Giacomo Leopardi 25<br>
        +39 379 189 9153<br>
        issima262@gmail.com<br>`,
        confirmButtonColor: "#3085d6"
    });
}