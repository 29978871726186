import './App.css';
import React, { useState, useEffect } from 'react';
import { sendEmail } from './Utility/Mail';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import DeliveryForm from './Components/DeliveryForm/DeliveryForm';
import ProductList from './Components/ProductList/ProductList';
import { getSubject, getBody } from './Utility/MailUtils';
import { showConfrimWindow } from './Components/ConfirmWindow/ConfirmWindow';
import { displayErrorWindow } from './Components/ErrorWindow/ErrorWindow';
import ProductItem from './Components/ProductItem/ProductItem';
import OrderSummary from './Components/OrderSummary/OrderSummary';

function App() {
  const [products, setProducts] = useState([]);
  const [selectedProds, setSelectedProds] = useState([]);
  const [isOrderConfirmed, setIsOrderConfirmed] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const [isClickedCart, setIsClickedCart] = useState(false);

  useEffect(() => {
    fetch('/data/products.json')
      .then(response => response.json())
      .then(data => {
        setProducts(data);
        if (selectedProds.length === 0) {
          const initialSelectedProds = data.map(prod => ({
            productName: prod.productName,
            cost: prod.cost,
            productType: prod.productType,
            imagePath: prod.imagePath,
            quantity: 0,
          }));
          setSelectedProds(initialSelectedProds);
        }
      })
      .catch(error => console.error('Error fetching products:', error));
  }, [selectedProds.length]);

  const onSelectedProdsChange = (updatedProds) => {
    setSelectedProds(updatedProds);
  };

  const onCartClick = () => {
    setIsClickedCart(!isClickedCart);
  };

  const getNumberOfSelectedProds = () => {
    return selectedProds
      .map(prod => prod.quantity)
      .reduce((acc, val) => acc + val, 0);
  };

  const getProductsCost = () => {
    return selectedProds
      .map(prod => prod.cost * prod.quantity)
      .reduce((acc, val) => acc + val, 0)
      .toFixed(2);
  };

  const onChangeQuantity = (prodName, quantity) => {
    const updatedProds = selectedProds.map(prod =>
      prod.productName === prodName ? { ...prod, quantity } : prod
    );
    onSelectedProdsChange(updatedProds);
  }

  const getShipmentCost = () => {
  
    const numOfFoodProds = selectedProds
      .filter(prod => prod.productType === 'Alimenti' && prod.quantity > 0)
      .length;

    const foodsCost = selectedProds
      .filter(prod => prod.productType === 'Alimenti' && prod.quantity > 0)
      .map(prod => prod.cost * prod.quantity)
      .reduce((acc, val) => acc + val, 0)
      .toFixed(2);

    const firstShipmentPart = foodsCost > 50.00 || numOfFoodProds === 0 ? 0.00 : 5.00

    const secondShipmentPart =
      selectedProds.filter(prod => prod.productType === 'Bevande' && prod.quantity > 0).length > 0 &&
      selectedProds
        .filter(prod => prod.productType === 'Bevande' && prod.quantity > 0)
        .map(p => p.quantity)
        .reduce((acc, val) => acc + val, 0) < 5
        ? 5.00
        : 0.00;

        const thirdShipmentPart =
        selectedProds.filter(prod => prod.productType === 'Pellet' && prod.quantity > 0).length > 0 &&
        selectedProds
          .filter(prod => prod.productType === 'Pellet' && prod.quantity > 0)
          .map(p => p.quantity)
          .reduce((acc, val) => acc + val, 0) < 5
          ? 5.00
          : 0.00;


    return Math.max(firstShipmentPart, secondShipmentPart, thirdShipmentPart);
  };

  const getTotalCost = () => {
    return (parseFloat(getProductsCost()) + parseFloat(getShipmentCost())).toFixed(2);
  };

  const onFormDelivered = (formData) => {
    showConfrimWindow("Vuoi confermare l'ordine?").then((res) => {
      if (res) {
        let requestBody = {
          subject: getSubject(formData),
          message: getBody(formData, selectedProds, getProductsCost(), getShipmentCost(), getTotalCost()),
        };
        sendEmail(requestBody).then((sendigEmailResponse) => {
          if (sendigEmailResponse) {
            setIsMailSent(true);
          } else {
            displayErrorWindow();
          }
        });
      }
    });
  };

  const onOrderConfirmed = () => {
    showConfrimWindow("Vuoi ordinare questi prodotti?").then((res) => {
      if (res) {
        setIsOrderConfirmed(true);
      }
    });
  };

  const renderPage = () => {
    if (isOrderConfirmed && !isMailSent) return deliveryPage();
    else if (!isOrderConfirmed && !isMailSent) return productsPage();
    else return orderSummaryPage();
  };

  const productsPage = () => {
    return (
      <>
        <Header />
        {isClickedCart ? (
          getCartSection()
        ) : (
          <ProductList
            products={products}
            selectedProds={selectedProds}
            onSelectedProdsChange={onSelectedProdsChange}
          />
        )}
        <Footer
          numOfProds={getNumberOfSelectedProds()}
          prodsCost={getProductsCost()}
          shipmentCost={getShipmentCost()}
          totalCost={getTotalCost()}
          onOrderConfirmed={onOrderConfirmed}
          onCartClick={onCartClick}
        />
      </>
    );
  };

  const deliveryPage = () => {
    return <DeliveryForm onFormDelivered={onFormDelivered} />;
  };

  const orderSummaryPage = () => {
    return (
      <OrderSummary
        selectedProds={selectedProds}
        prodsCost={getProductsCost()}
        shipmentCost={getShipmentCost()}
        totalCost={getTotalCost()}
      />
    );
  };

  const getCartSection = () => {
    return (
      <div>
        <h3><strong>ARTICOLI NEL CARRELLO</strong></h3>
        {selectedProds.map(product => (
              product.quantity > 0 &&
              <ProductItem
              key={product.id}
              name={product.productName}
              price={product.cost}
              imagePath={`images/${product.imagePath}`}
              quantity={selectedProds.find(p => p.productName === product.productName)?.quantity || 0}
              onChangeQuantity={onChangeQuantity}
            />        
        ))}
      </div>
    );
  };

  return <div className="App">{renderPage()}</div>;
}

export default App;
