import emailjs from '@emailjs/browser';
import {INIT_SEED, SERVICE_ID, TEMPLATE_ID} from './credentials'

export function sendEmail(inputValues) {

    const initSeed = INIT_SEED;
    const serviceId = SERVICE_ID;
    const templateId = TEMPLATE_ID;

    return new Promise((resolve, reject) => {
        emailjs.send(serviceId, templateId, inputValues, initSeed)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                resolve(true)
            }, (error) => {
                console.log('FAILED...', error);
                resolve(false)
            });
    })

};